import React from "react"

import Seo from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMicrophone } from "@fortawesome/free-solid-svg-icons"
import TalkList from "../components/talk-list"

const OverviewPage = ({ data, pageContext }) => {
    const { name, content } = pageContext
    // console.log("Creating", name, "overview page with data", content)

    // Sort per type

    const talks = content["talk"].map(node => {
        return { "node": node }
    })
    // console.log("talks", talks)

    return (
        <>
            <Seo title={name}/>


            {talks && talks.length > 0 && <section>

                <section>
                    <h2>
                        <FontAwesomeIcon icon={faMicrophone}
                                         className={"fa-fw header-icon"}
                                         size={"sm"}/>
                        Talks related to {name}
                    </h2>
                    <TalkList talks={talks}
                              sortDate={true}/>
                </section>
            </section>}
        </>
    )
}

export default OverviewPage


