import React from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo"
import PictureList from "../components/picture-list"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faBookmark,
    faImage, faStar, faUser,
    faUserCircle
} from "@fortawesome/free-solid-svg-icons"

const showPictures = pictures => {
    if (pictures.length > 0) {
        return (
            <PictureList
                pictures={pictures}
                displayText={true}
                displaySource={true}
                square={true}
            />
        )
    }
}

const PicturesPage = ({ data }) => {
    const pictures = data.allPicturesJson.edges.map(({ node }) => node)

    const profilePictures = pictures.filter(picture =>
        picture.tags?.includes("profile")
    )
    const featuredPictures = pictures.filter(picture =>
        picture.tags?.includes("featured")
    )
    const otherPictures = pictures.filter(
        picture =>
            !profilePictures.includes(picture) &&
            !featuredPictures.includes(picture)
    )

    return (
        <>
            <Seo title="Pictures" />
            <h1>Pictures</h1>
            <p>
                This page contains pictures of me taken during talks and
                interviews. Click on any picture to enlarge.
            </p>

            <section>
                <h2>
                    <FontAwesomeIcon
                        icon={faUserCircle}
                        className={"header-icon"}
                        size={"sm"}
                    />
                    Profile pictures
                </h2>
                <p>
                    Pictures that can be used as profile pictures in
                    announcements for my <Link to="/talks">talks</Link>.
                </p>
                {showPictures(profilePictures)}
            </section>
            <section>
                <h2>
                    <FontAwesomeIcon
                        icon={faStar}
                        className={"header-icon"}
                        size={"sm"}
                    />
                    Featured pictures
                </h2>
                <p>
                    Some of my favourite pictures from{" "}
                    <Link to="/talks">talks</Link> and{" "}
                    <Link to="/press">interviews</Link>.
                </p>
                {showPictures(featuredPictures)}
            </section>
            <section>
                <h2>
                    <FontAwesomeIcon
                        icon={faImage}
                        className={"header-icon"}
                        size={"sm"}
                    />
                    Other pictures
                </h2>
                {showPictures(otherPictures)}
            </section>
        </>
    )
}

export default PicturesPage

export const query = graphql`
    query {
        allPicturesJson(sort: { fields: [priority], order: [DESC] }) {
            edges {
                node {
                    id
                    ...PictureFragment
                }
            }
        }
    }
`
