const pictureComparator = (a, b) => {
    const aPriority = a.priority || 0
    const bPriority = b.priority || 0
    if (aPriority !== bPriority) {
        return bPriority - aPriority
    }
    if (!a.file || !b.file) {
        if (!a.file) {
            console.error("Missing file in picture", a)
        } else {
            console.error("Missing file in picture", b)
        }
        return a.file ? -1 : 1
    }
    const aName = a.file.name
    const bName = b.file.name

    // Get number in front of name to get the year
    const aYearMatch = aName.match(/\d+/)
    const bYearMatch = bName.match(/\d+/)
    if (aYearMatch && bYearMatch) {
        // sort highest year on the top
        const aYear = aYearMatch[0]
        const bYear = bYearMatch[0]
        if (aYear !== bYear) {
            return bYear - aYear
        }
    }
    return aName < bName ? -1 : 1
}

const getMainPicture = function(pictures) {
    if (!pictures.length) {
        return
    }
    return pictures.reduce(function(prev, curr) {
        return pictureComparator(prev, curr) < 0 ? prev : curr
    })
}

module.exports = { getMainPicture, pictureComparator }

