import React from "react"
import { graphql, Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import Seo from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faBookmark,
    faCalendarCheck,
    faHistory,
    faMicrophone,
} from "@fortawesome/free-solid-svg-icons"

import TalkList from "../components/talk-list"

import {
    contentBadge,
    tableOfContents,
    tableOfContentsList,
} from "./academic.module.scss"
import { headerIcon } from "./appearances.module.scss"
import TalkTypeList from "../components/talk-type-list"

const TalksPage = ({ data }) => {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const upcomingTalks = data.allTalksJson.edges.filter(
        e => new Date(e.node.date) > today
    )
    const pastTalks = data.allTalksJson.edges.filter(
        e => new Date(e.node.date) < today
    )

    return (
        <>
            <Seo title="Talks" />
            <h1>Talks</h1>
            <p>
                As a researcher, I often receive the opportunity to talk about
                my <Link to={"/projects"}>projects</Link> and my{" "}
                <Link to={"/academic"}>creative AI research</Link> publicly.
                This page lists the types of talks I usually present on request
                for events, companies and higher education, as well as a
                selection of my future and past talks.
                {/*This page lists all these types of public appearances.*/}
                {/*<span>*/}
                {/*A list of public talks I've presented over the years, or will present in the near future.*/}
                {/*    Excluded are many internal talks <em> (e.g. within <Link*/}
                {/*    to={"/academic#position"}>our research lab</Link>)</em> and private workshops </span><em>*/}
                {/*    <span>*/}
                {/*        (e.g. <Link to={"/tag/improv"}>improv</Link> workshops for <Link*/}
                {/*        to={"/preparee"}>Preparee</Link> & others)*/}
                {/*    </span>*/}
                {/*</em>.*/}
            </p>
            <p>
                If you'd like to interview me or invite me for a talk, you can
                contact me on my <Link to={"/contact"}>contact form</Link>.
            </p>

            {/*<section className={tableOfContents}>*/}
            {/*    <h2>*/}
            {/*        <FontAwesomeIcon icon={faBookmark}*/}
            {/*                         className={headerIcon}*/}
            {/*                         size={"sm"}/>*/}
            {/*        Table of Contents*/}
            {/*    </h2>*/}
            {/*    <div>*/}
            {/*        {upcomingTalks.length > 0 && <ul className={"fa-ul " + tableOfContentsList}>*/}
            {/*            <li>*/}
            {/*                <FontAwesomeIcon icon={faCalendarCheck} className={headerIcon} listItem/>*/}
            {/*                <AnchorLink to="/talks#upcoming">*/}
            {/*                    Upcoming Talks*/}
            {/*                    <span className={contentBadge}>{upcomingTalks.length}</span>*/}
            {/*                </AnchorLink>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                <FontAwesomeIcon icon={faHistory} className={headerIcon} listItem/>*/}
            {/*                <AnchorLink to="/talks#past">*/}
            {/*                    Past Talks*/}
            {/*                    <span className={contentBadge}>{pastTalks.length}</span>*/}
            {/*                </AnchorLink>*/}
            {/*            </li>*/}

            {/*        </ul>*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*</section>*/}

            <section id={"types"}>
                <h2>
                    <FontAwesomeIcon
                        icon={faMicrophone}
                        className={headerIcon}
                    />
                    Talk Types
                </h2>
                <p>
                    Below are several of my most popular types of talks, ranging
                    from general keynotes to more technical talks and workshops.
                    You can <Link to={"/contact"}>contact me</Link> for more
                    information or to request one of these talks for your event.
                </p>
                <TalkTypeList talkTypes={data.allTalkTypesJson.edges} />
            </section>

            <section id={"talks"}>
                {/*<h2>*/}
                {/*    <FontAwesomeIcon icon={faMicrophone} className={headerIcon}/>*/}
                {/*    Talks*/}
                {/*</h2>*/}
                {/*<p>*/}
                {/*</p>*/}
                {upcomingTalks && (
                    <section
                        id={"upcoming"}
                        className={upcomingTalks.length ? "" : "invisible"}
                    >
                        <h2>
                            <FontAwesomeIcon
                                icon={faCalendarCheck}
                                className={headerIcon}
                            />
                            Upcoming Talks
                        </h2>
                        <p>
                            Below are {upcomingTalks.length} public talks I'll be
                            presenting in the near future.
                        </p>
                        <TalkList talks={upcomingTalks} />
                    </section>
                )}
                {pastTalks && (
                    <section id={"past"}>
                        <h2>
                            <FontAwesomeIcon
                                icon={faHistory}
                                className={headerIcon}
                            />
                            Past Talks
                        </h2>
                        <p>
                            Below are {pastTalks.length} public talks I've
                            presented in the past.
                            Excluded are many internal talks & workshops
                            <em>
                                {" "}
                                (e.g. within{" "}
                                <Link to={"/academic#position"}>research labs</Link>,
                                for company NDA reasons or{" "}
                                <Link to={"/tag/improv"}>improv</Link> workshops){" "}
                            </em>
                        </p>
                        <TalkList talks={pastTalks} />
                    </section>
                )}
            </section>
        </>
    )
}

export default TalksPage

export const query = graphql`
    query {
        allTalksJson(sort: { fields: [date], order: DESC }) {
            edges {
                node {
                    id
                    ...TalkFragment
                }
            }
        }
        allTalkTypesJson {
            edges {
                node {
                    id
                    ...TalkTypeFragment
                    cover {
                        childImageSharp {
                            gatsbyImageData(
                                width: 660,
                                placeholder: BLURRED,
                                formats: [AUTO, PNG, WEBP],
                                layout: CONSTRAINED
                            )
                        }
                    }
                }
            }
        }
    }
`
